.color4 {
    color: orange;
    text-align: center;
}

/* Upload Button */
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn {
    border: 2px solid white;
    color: white;
    background-color: #1890ff;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

/* Upload Button & Hover Delete */
.container {
    position: relative;
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.container:hover .image {
    opacity: 0.3;
}

.container:hover .middle {
    opacity: 1;
}

.text {
    background-color: #04AA6D;
    color: white;
    padding: 8px 16px;
}